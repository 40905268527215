import React, { Component } from 'react';
import NavBar from '../../AppNav/NavBar'
import SideNav from '../../AppNav/SideNav'
import Wrapper from '../../Wrapper/Wrapper'
import Logo from '../../AppNav/Logo'
import ValidatePage from '../../AppNav/ValidatePage'
import { connect } from 'react-redux'
import StudentList from '../../ResultsList/StudentList'
import PropTypes from 'prop-types';
import { withAITracking } from 'react-appinsights';

class AccueilEnseignant extends Component {

  render() {

    let allStudents = [];
    // Returns all courses
let allCourses = this.props.whoami.data[0].courses;

if (!allCourses) {
    allCourses = [];
} else {
    allCourses = allCourses.filter(course => {
        return course.students && Array.isArray(course.students) && course.students.length > 0;
    });
}

    for(var i=0; i<allCourses.length; i++){
      // Returns students for current course
      let students = allCourses[i].students;
         
      for(var n=0; n<students.length; n++){
          allStudents.push({
              name: students[n].firstName + " " + students[n].lastName,
              id: students[n].id
          })
      }
    }

    return(
        <Wrapper>
            <header>
              <NavBar role={this.props.whoami.data[0].role} />
              <SideNav role={this.props.whoami.data[0].role} />
              <Logo section="home" />
              <ValidatePage type="img" section="home" />
            </header>
            <main className="container mt-5">
                <div className="box p-3" id="main">
                    <h1 className="surveySectionTitle sectionGrayTitle fontSize500">Ma liste d’élèves</h1>
                </div>
                <div className="col-12 my-4 p-0">
                  {allCourses.map((item, i) => <StudentList key={i} course={item} index={i} />)}
                </div>
            </main>
        </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    whoami: state.whoami
  }
}

AccueilEnseignant.propTypes = {
  whoami: PropTypes.object.isRequired
}

export default withAITracking(connect(mapStateToProps)(AccueilEnseignant));
